<template>
    <b-form @submit.stop.prevent="submitNewLandmark"
    >
        <b-form-group
          id="input-group-1"
          label="Landmark Name:"
          label-for="input-1"
        >
          <b-form-input
            id="inputLandmark"
            v-model="landmarkForm.landmarkName"
            placeholder="Enter Landmark"
            required
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Email address:"
          label-for="input-1"
          description="We'll never share your email with anyone else."
        >
          <b-form-input
            id="inputEmail"
            v-model="landmarkForm.email"
            type="email"
            placeholder="Enter email"
            required
          />
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Fun Story About This Landmark:"
          label-for="input-3"
        >
        <b-form-textarea
          id="textarea"
          v-model="landmarkForm.story"
          placeholder="If you would like, you can submit a fun story you had at this location..."
          rows="3"
          max-rows="3"
        ></b-form-textarea>
        </b-form-group> 
      <b-button class="mt-3" variant="primary" block type="submit">Submit</b-button>
      <b-button class="mt-3" variant="outline-danger" block @click.prevent="cancelLandmarkForm">Cancel</b-button>
    </b-form>
</template>

<script>

export default {
  name: 'NewLandmarkForm',
  props: {
    destination: String,
    pinLocation: Object
  },
  data: function()
  {
    return {
      landmarkForm:{
        landmarkName: '', 
        email: '',
        name: '',
        story: ''
        }
    }
  },  
  methods: {
    formatLandmarkSubmisstion: function(){
      const formattedLandmark = {
        "subject": "New Landmark Submission",
        "destination": this.destination,
        "landmarkName": this.landmarkForm.landmarkName,
        "email": this.landmarkForm.email,
        "name": this.landmarkForm.name,
        "story": this.landmarkForm.story,
        "cx": this.pinLocation.cx,
        "cy": this.pinLocation.cy
      };
      return formattedLandmark;
    },
    submitNewLandmark: function(event){
        event.preventDefault();
        const result = this.formatLandmarkSubmisstion();
        try{
          fetch("/.netlify/functions/sendemail", {
            method: "POST",
            body: JSON.stringify(result),
          }).then(res=>{
            if (res.status == 200) {
              this.$emit('submitNewLandmark', result);
            }
            else{
              console.log("is there an issue")
              this.$bvToast.toast("please check the information you provided is correct and try again.", {
                title: "Unexpected Error When Submitting Landmark",
                variant: "danger",
                solid: true,
                timeOut: 3000
              });
            }
          }).catch(err=>{
            console.log(err);
            this.$bvToast.toast("please check the information you provided is correct and try again.", {
              title: "Unexpected Error When Submitting Landmark",
              variant: "danger",
              solid: true,
              timeOut: 3000
            });
          })
        } catch(e){
          this.$bvToast.toast("please check the information you provided is correct and try again.", {
            title: "Unexpected Error When Submitting Landmark",
            variant: "danger",
            solid: true,
            timeOut: 3000
          });
        }
    },
    cancelLandmarkForm: function(){
        this.landmarkForm.landmarkName = '';
        this.landmarkForm.story = '';
        this.$emit('cancelLandmarkForm', this.landmarkForm);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
