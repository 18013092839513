import { render, staticRenderFns } from "./NewLandmarkForm.vue?vue&type=template&id=d28cfe8a&scoped=true&"
import script from "./NewLandmarkForm.vue?vue&type=script&lang=js&"
export * from "./NewLandmarkForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d28cfe8a",
  null
  
)

export default component.exports