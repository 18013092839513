<template>
<b-modal v-model="isShowModal" id="mugShot" class="container" size="xl" :title="mugDetail.mugName"
@hide="closeMugShotDetail" @shown="onShown" hide-footer>
  <div class= "mb-1">
    <b-input-group size="sm" class="mt-3" v-if="!addNewPinMode">
      <template #prepend class="mt-3" >
        <b-input-group-text id="lblAddLandmark">Recognize landmarks that are not pinned?</b-input-group-text>
      </template>
      <b-input-group-append>
      <b-button variant="primary" size="sm" @click="startNewPinMode" ref="btnAddNewLandmark">Add It Yourself!</b-button>
    </b-input-group-append>
    </b-input-group>
    <b-input-group size="sm" class="mt-3" v-if="addNewPinMode">
      <template #prepend class="mt-3">
        <b-input-group-text id="lblAddLandmark">Click on the landmark you would like to pin, or </b-input-group-text>
      </template>
      <b-input-group-append>
      <b-button variant="outline-danger" size="sm" @click="endNewPinMode" ref="btncancelNewLandmark">Cancel</b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
  <div class="img-overlay-wrap" :height=mugDetailSize.height :width=mugDetailSize.width>
    <b-img  ref="imgMug" :src="mugDetail.image" fluid></b-img>
    <svg ref='svgPins' id="svgPins" @click="addPin" @mousemove="newPinDrag" 
    v-bind="svgBinds"
    :height="mugDetailSize.height"
    :width="mugDetailSize.width"
    :cursor="addNewPinMode? 'pointer' : 'default'">
    <circle id="newPinPopover" class="medium" :cx=newPin.cx :cy=newPin.cy r="2%" fill="green" stroke="lightgreen" stroke-width="0.5%"
    v-if="addNewPinMode"
    @click="isPinDraggable=false">
      <b-popover target="newPinPopover" triggers="click" ref="popover2" placement="right">
        <template #title>New Pin Land Mark</template>
        <NewLandmarkForm
          :destination="mugDetail.mugName"
          :pinLocation="newPin"
          v-on:submitNewLandmark="submitNewLandmark"
          v-on:cancelLandmarkForm="cancelLandmarkForm"
        />
      </b-popover>
    </circle>
    <circle v-for="pin in mugDetail.pins"  :key="pin.landmark" :id=pin.landmark class="medium" :cx=pin.cx :cy=pin.cy r="2%" fill="blue" stroke="lightblue" stroke-width="0.5%">
      <b-popover :target=pin.landmark triggers="hover focus" ref="popover" placement="right">
        <template #title>{{pin.landmark}}</template>
          <div>
          <b-button variant="primary" :href=pin.detailLink target="_blank" size="sm">Find Out More!</b-button>
          
          <b-input-group size="sm" class="mt-3">
            <template #prepend class="mt-3" >
              <b-input-group-text id="disagreeLabel" >Disagree with Pin?</b-input-group-text>
            </template>
            <b-input-group-append>
                <b-button variant="primary" size="sm" @click="openCorrectionModal (pin.landmark)" ref="btnCorrection">Send Correction!</b-button>
            </b-input-group-append>

          </b-input-group>
          </div>
      </b-popover>
    </circle>
    <UnderReviewPin v-for="pin in userAddedPins" :key="pin.landmark" :pin=pin />
    </svg>
    <CorrectionModal :isShowModal=correctionModalShow
    :landmarkName="currentLandmark"
    :destination="mugDetail.mugName"
    v-on:submitCorrection="submitCorrection"
    v-on:closeCorrectionModal="closeCorrectionModal"/>
  </div> 
</b-modal>
</template>

<script>
import CorrectionModal from './CorrectionModal.vue';
import NewLandmarkForm from './NewLandmarkForm.vue';
import UnderReviewPin from './UnderReviewPin.vue';
export default {
  name: 'MugShotDetails',
  components: {
    CorrectionModal,
    NewLandmarkForm,
    UnderReviewPin,
  },
  props: {
    mugDetail: Object,
    isShowModal: Boolean,
  },
  mounted(){ 
    // Register an event listener when the Vue component is ready
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
  },
  data: function()
  {
    return {
      mugDetailSize:{
        height: 430, 
        width: 800
        },
      currentLandmark: '',
      correctionModalShow: false,
      addNewPinMode: false,
      isPinDraggable: false,
      showNewLandmarkPopover: false,
      newPin: {
         cx: "50%",
         cy: "50%",

      },
      userAddedPins: [],

    }

  },
	computed: {
		svgBinds: function() {
      var widthSize = this.mugDetailSize.width;
      var heightSize = this.mugDetailSize.height;
			return {
        viewBox: `0,0, ${widthSize}, ${heightSize}`,
			}
		}
  },
  methods:{
    onResize(){
      this.mugDetailSize.width = this.$refs.imgMug.width;
      this.mugDetailSize.height = this.$refs.imgMug.height;
    },
    onShown(){
      this.mugDetailSize.width = this.$refs.imgMug.width;
      this.mugDetailSize.height = this.$refs.imgMug.height;
    },
    closeMugShotDetail: function(){
      this.$emit('closeMugShotDetail');
    },
    startNewPinMode: function(){
      this.newPin.cx = "50%";
      this.newPin.cy = "50%";
      this.addNewPinMode = true;
      this.isPinDraggable = true;
    },
    endNewPinMode: function(){
      this.$root.$emit('bv::hide::popover');
      this.addNewPinMode = false;
      this.isPinDraggable = false;    
    },
    newPinDrag: function(e){
      if (this.addNewPinMode && this.isPinDraggable){
        this.newPin.cx = e.offsetX;
        this.newPin.cy = e.offsetY;
      }
    },
    addPin: function(e){
      if (this.addNewPinMode){
        this.newPin.cx = e.offsetX;
        this.newPin.cy = e.offsetY;
        this.newPin.cx = `${(this.newPin.cx/this.mugDetailSize.width)*100}%`;
        this.newPin.cy = `${(this.newPin.cy/this.mugDetailSize.height)*100}%`;
      }
    },
    openCorrectionModal: function(landmark){
      this.currentLandmark = landmark;
      this.$root.$emit('bv::hide::popover')
      this.correctionModalShow = true;
      // console.log(pinName);
    },
    closeCorrectionModal: function(event){
      this.correctionModalShow = false;
      console.log(event);
    },
    submitCorrection: function(event){
      console.log(event);
      this.closeCorrectionModal();
    },
    submitNewLandmark(newLandmark) {
      console.log(newLandmark);
      const addedPin = {
        'cx': this.newPin.cx,
        'cy': this.newPin.cy,
        'landmark': newLandmark.landmarkName
      };
      this.userAddedPins.push(addedPin);
      this.endNewPinMode();
      this.$bvToast.toast("Thank you for your submission. It is now under review", {
        title: "New Landmark Submitted",
        variant: "success",
        solid: true,
        timeOut: 3000
      });
    },
    cancelLandmarkForm: function(event){
      this.$root.$emit('bv::hide::popover')
      this.endNewPinMode();
      console.log(event);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#mugShot {
  height: 20%;
  width: 60%;
}
#disagreeLabel{
  background-color: transparent;
  border: none;
}
#lblAddLandmark{
  background-color: transparent;
  border: none;
}
.img-overlay-wrap {
  position: relative;
  display: inline-block; /* <= shrinks container to image size */
}

.img-overlay-wrap img { /* <= optional, for responsiveness */
   display: block;
   /* max-width: 100%; */
   /* height: auto; */
}

.img-overlay-wrap svg {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  background-color: var(--v-primary-lighten1);
  border: 2px solid black;
}
svg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--v-primary-lighten1);
  border: 2px solid black;
}
</style>
