<template>
    <b-modal v-model=isShowModal hide-footer  title="Send Mugshot"
    id='mugShotFormModal'
    @hide="closeMugShotForm">
      <b-form @submit.stop.prevent="submitMugShot">
         <b-progress :value="progress" variant="success">
         </b-progress>
        <b-card v-if="currentStep==1" class="card-style">
        <b-form-group
          id="input-group-1"
          label="Location of the Mug:"
          label-for="input-1"
          description="Usually written in big letter somewhere on the mug"
        >
          <b-form-input
            id="inputLandmark"
            v-model="mugShotForm.mugName"
            :state="mugShotForm.mugName.length > 2"
            placeholder="Enter Mug Location"
            required
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Email address:"
          label-for="input-1"
          description="We'll never share your email with anyone else."
        >
          <b-form-input
            id="inputEmail"
            v-model="mugShotForm.email"
            :state="isEmailValid"
            type="email"
            placeholder="Enter email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Your Name: (Optional)"
          label-for="input-2"
          description="Take credit for uploading this mug!"

        >
          <b-form-input
            id="inputName"
            v-model="mugShotForm.name"
            type="text"
            placeholder="Enter name (Optional)"
          ></b-form-input>
        </b-form-group>
        <b-button id="moveForwardToUpload" class="float-right" variant="primary" @click="moveForwardToImageUpload" :disabled="!isLocationDetailsValid">Next</b-button>
    </b-card>
    <b-card v-if="currentStep==2" class="card-style" title="Upload Guidelines">
    <b-container fluid class="p-4">
      <ul>
         <li>Take pictures with your phone horizontally</li>
         <li>The top and bottom of the mug should just fit into the shot</li>
         <li>Take 3 pictures of the mug to clearly see all landmarks</li>
      </ul>
      <h3>Example:</h3>
      <b-row class="mb-2">
        <b-col>
          <b-img thumbnail src="./mugs/example/ex1.jpg" 
          class="imageUploads" alt="Front"></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail src="./mugs/example/ex2.jpg" 
          class="imageUploads" alt="Middle"></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail src="./mugs/example/ex3.jpg" 
          class="imageUploads" alt="Back"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-img thumbnail 
          :src="previewFrontImage? previewFrontImage : './icons/download.png'" 
          class="imageUploads"
          @click="chooseImageUpload('inputFrontImage')"
          alt="Front"></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail :src="previewMiddleImage? previewMiddleImage : './icons/download.png'" 
          class="imageUploads"
          @click="chooseImageUpload('inputMiddleImage')"
          alt="Middle"></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail :src="previewBackImage? previewBackImage : './icons/download.png'" 
          class="imageUploads"
          @click="chooseImageUpload('inputBackImage')"
          alt="Back"></b-img>
        </b-col>
      </b-row>
    </b-container>
      <b-form-file id='inputFrontImage' v-model="mugShotForm.image1" class="mt-3" 
      accept="image/jpeg, image/jpg, image/png, image/gif" plain hidden
      @change=uploadFrontImage>
      </b-form-file>
      <b-form-file id='inputMiddleImage' v-model="mugShotForm.image2" class="mt-3" 
      accept="image/jpeg, image/jpg, image/png, image/gif" plain hidden
      @change=uploadMiddleImage>
      </b-form-file>
      <b-form-file id='inputBackImage' v-model="mugShotForm.image3" class="mt-3" 
      accept="image/jpeg, image/jpg, image/png, image/gif" plain hidden
      @change=uploadBackImage>
      </b-form-file>
      <b-button class="float-left" variant="secondary" @click="moveBackToLocationDetails">Back</b-button>
      </b-card>
      <b-button v-if="currentStep == 2" class="mt-3" variant="primary" block type="submit"
      :disabled="!isProcessComplete"
      >Submit</b-button>
      <b-button class="mt-3" variant="outline-danger" block @click="closeMugShotForm">Cancel</b-button>
      </b-form>

  </b-modal>
</template>

<script>

export default {
  name: 'MugShotFormModal',
  props: {
    isShowModal: Boolean,
  },
  data: function(){
    return {
      currentStep: 1,
      previewFrontImage: null,
      previewMiddleImage: null,
      previewBackImage: null,
      mugShotForm: {
        mugName: '',
        email: '',
        name: '',
        image1: '',
        image2: '',
        image3: ''
  
      }
    }
  },
  computed: {
    progress: function() {
      return this.isProcessComplete? 100 : Math.round(100 / 2) * (this.currentStep-1);
    },
    isEmailValid: function(){
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
      const isEmailValid = emailRegex.test(this.mugShotForm.email);
      
      return isEmailValid;
    },
    isLocationDetailsValid: function(){
      return this.mugShotForm.email && this.isEmailValid;
    },
    isProcessComplete: function(){
      return (this.mugShotForm.email &&
              this.mugShotForm.mugName && 
              this.mugShotForm.image1 && 
              this.mugShotForm.image2);
      }
  },
  methods: {
    submitMugShot: function(event){
        event.preventDefault();
        try{
          const images = [];
          if (this.previewFrontImage){
            images.push(this.previewFrontImage);
          }
          if (this.previewMiddleImage){
            images.push(this.previewMiddleImage);
          }
          if (this.previewBackImage){
            images.push(this.previewBackImage);
          }
          const formattedResult = {
            "subject": "New Mug Submission",
            "mugName": this.mugShotForm.mugName,
            "email": this.mugShotForm.email,
            "name": this.mugShotForm.name,
            "images": images
          }
          fetch("/.netlify/functions/sendemailwithattachment", {
            method: "POST",
            body: JSON.stringify(formattedResult),
          }).then((res) => {
            console.log(res);
            if (res.status == 200) {
              this.$bvToast.toast("Thank you for your submission. It is now under review", {
                title: "New Mug Submitted",
                variant: "success",
                solid: true,
                timeOut: 3000
              });
              this.$emit('submitMugShot');
            }
            else{
              this.$bvToast.toast("please check the information you provided is correct and try again.", {
                title: "Unexpected Error When Submitting Mug",
                variant: "danger",
                solid: true,
                timeOut: 3000
              });              
            }
          }

          ).catch((err) =>{
            console.log(err);
            this.$bvToast.toast("please check the information you provided is correct and try again.", {
              title: "Unexpected Error When Submitting Mug",
              variant: "danger",
              solid: true,
              timeOut: 3000
            });
          });
       
          
        } catch(e){
          console.log(e);
        }

    },
    closeMugShotForm: function(){
        this.$emit('closeMugShotForm', {"message": "form canceled"});
    },
    moveBackToLocationDetails(){
      this.currentStep = 1;
    },
    moveForwardToImageUpload(){
      this.currentStep = 2;
    },
    chooseImageUpload(id){
      document.getElementById(id).click()
    },
    uploadFrontImage(e){
        const image = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            this.previewFrontImage = e.target.result;
        };
    },
    uploadMiddleImage(e){
        const image = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            this.previewMiddleImage = e.target.result;
        };
    },
    uploadBackImage(e){
        const image = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            this.previewBackImage = e.target.result;
        };
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.imageUploads{
  cursor: pointer;
}
ul {
    padding: 0;
    font-size: 12px;
}
</style>
