export var mugDetails = [
    {
        "mugName": "Ontario",
        "pins":[
            {"landmark": "Ice Wine", "detailLink": "https://en.wikipedia.org/wiki/Ice_wine", "cx": "64.918%", "cy": "66.125%"},
            {"landmark": "Potatoes", "detailLink": "https://en.wikipedia.org/wiki/Yukon_Gold_potato", "cx": "42.04%", "cy": "16.009%"},
            {"landmark": "Niagara Falls", "detailLink": "https://en.wikipedia.org/wiki/Niagara_Falls", "cx": "9.584%", "cy": "66.589%"}


        ], 
        "image": "./mugs/Ontario/map.jpg"
    },
    {
        "mugName": "Canada",
        "pins":[

            {"landmark": "Parliament Hill", "detailLink": "https://en.wikipedia.org/wiki/Parliament_Hill", "cx": "8.137%", "cy": "79.118%"},
            {"landmark": "True North Strong And Free", "detailLink": "https://en.wikipedia.org/wiki/O_Canada#:~:text=The%20line%20%22The%20True%20North,loyal%22%20or%20%22faithful%22.", "cx": "51.898%", "cy": "9.512%"},
            {"landmark": "Mountain Police", "detailLink": "https://en.wikipedia.org/wiki/Royal_Canadian_Mounted_Police", "cx": "12.839%", "cy": "34.10%"},
            {"landmark": "Snow", "detailLink": "https://en.wikipedia.org/wiki/Temperature_in_Canada", "cx": "23.688%", "cy": "18.097%"},
            {"landmark": "Hockey", "detailLink": "https://en.wikipedia.org/wiki/Hockey_Canada", "cx": "51.898%", "cy": "56.844%"},
            {"landmark": "Tuke", "detailLink": "https://www.quora.com/What-is-a-Canadian-tuke", "cx": "53.616%", "cy": "83.758%"},
            {"landmark": "Poutine", "detailLink": "https://en.wikipedia.org/wiki/Poutine", "cx": "72.423%", "cy": "81.670%"},
            {"landmark": "Maple Syrup", "detailLink": "https://en.wikipedia.org/wiki/Maple_syrup", "cx": "87.522%", "cy": "75.870%"},
            {"landmark": "Beaver", "detailLink": "https://www.thecanadianencyclopedia.ca/en/article/beaver", "cx": "92.676%", "cy": "47.331%"},
            {"landmark": "Moose", "detailLink": "https://www.reference.com/world-view/moose-canadian-symbol-da5de847f74a3fe3", "cx": "96.9258%", "cy": "69.141%"},





        ], 
        "image": "./mugs/Canada/map.jpg"
    }
]