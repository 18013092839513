<template>
  <b-modal id="aboutUsModal" hide-footer size='lg' title="About Us">
      <h2>Our Mission</h2>
      <p>To have the world's most comprehensive community-driven database of Been There mugs and their corresponding landmarks.
        ...At least until Starbucks decides they should really be doing this themselves, like come on, honestly?
      </p>
      <h2>Why?</h2>
      <p>As an ethusiasts of the Been There mugs we find it exhausting to search the internet for the landmarks on the mug.
        You either have to look at random posts and blogs and try to piece it all together yourself. Well no more! 
        <b>Just type in your mug location and get the answers you deserve </b>.
      </p>
  </b-modal>
</template>

<script>

export default {
  name: 'AboutModal',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
