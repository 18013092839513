export const state = {
    // experimentName: "",
    // trades: [],
};

export const mutations = {
    // ['setExperimentName'](state, result){
    //     state.experimentName = result.experimentName;
    // },
    // ['setTrades'](state, trades){
    //     state.trades = trades;
    // },
    // ['setUnextracbleRemittance'](state, value){
    //     state.unextractableRemittance = value;
    // },
    // ['setUnextracbleRemittanceResult'](state, value){
    //     state.unextractableRemittance.result = value;
    // },
    // ['setIsProcessing'](state,value){
    //     state.isProcessing = value;
    // },
    // ['setFileLocation'](state, value){
    //     state.fileLocation = value;
    // },
    // ['setDisplayWidth'](state,value){
    //     state.displayWidth = value;
    // },
    // ['setDisplayHeight'](state,value){
    //     state.displayHeight = value;
    // },
    // ['setHighlightContent'](state, options){
    //     let pages = state.remittance.remittancePages.filter(function(p){return p.pageNumber == state.pageIdx});
    //     if (pages.length>0){
    //         pages[0].contents[options.id].isHighlighted = options.isHighlighted;
    //     }
    // },
    // ['setHighlightedColumnIndex'](state, value){
    //     let pages = state.remittance.remittancePages.filter(function(p){return p.pageNumber == state.pageIdx});
    //     if (pages.length>0){
    //         pages[0].remittanceTable.updateHighlightedColumnIndex(value);
    //     }
    // },
    // ['setPageIdx'](state,value){
    //     state.pageIdx = value;
    // }
};
