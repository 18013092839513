<template>
    <b-modal v-model=isShowModal hide-footer  title="Feedback/Questions"
    @hide="closeContactFormModal">
      <b-form @submit.stop.prevent="submitContact">
        <b-form-group
          id="input-group-1"
          label="Email address:"
          label-for="input-1"
          description="We'll never share your email with anyone else."
        >
          <b-form-input
            id="inputEmail"
            v-model="contactForm.email"
            type="email"
            placeholder="Enter email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Name:"
          label-for="input-2"
        >
          <b-form-input
            id="inputName"
            v-model="contactForm.name"
            type="text"
            placeholder="Enter name (Optional)"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Feedback/Question:"
          label-for="input-3"
        >
        <b-form-textarea
          id="textarea"
          v-model="contactForm.message"
          placeholder="Enter Feedback Or Questions..."
          rows="3"
          max-rows="6"
          required
        ></b-form-textarea>
        </b-form-group> 
      <b-button class="mt-3" variant="primary" block type="submit">Submit</b-button>
      <b-button class="mt-3" variant="outline-danger" block @click="closeContactFormModal">Cancel</b-button>
      </b-form>
  </b-modal>
</template>

<script>

export default {
  name: 'ContactFormModal',
  props: {
    isShowModal: Boolean,
  },
  data: function(){
      return {
        contactForm: {
            email: '',
            name: '',
            message: ''
        }
      }
  },
  methods: {
    submitContact: function(event){
        event.preventDefault();        
        try{
          const formattedResult = {
              subject: "Feedback/Question Submission",
              email: this.contactForm.email,
              name: this.contactForm.name,
              correction: this.contactForm.message,
          }
          
          fetch("/.netlify/functions/sendemail", {
            method: "POST",
            body: JSON.stringify(formattedResult),
          }).then((res) => {
            if (res.status == 200) {
              this.$bvToast.toast("Thank you for your submission!", {
                  title: "Feedback/Question Submitted",
                  variant: "success",
                  solid: true,
                  timeOut: 3000
              });
              this.$emit('closeContactFormModal', {});
            }
            else{               
              this.$bvToast.toast("please check the information you provided is correct and try again.", {
                  title: "Unexpected Error When Submitting Feedback",
                  variant: "danger",
                  solid: true,
                  timeOut: 3000
              });
              return
          }
          }).catch((err) =>{
              console.log(err);
              this.$bvToast.toast("please check the information you provided is correct and try again.", {
                  title: "Unexpected Error When Submitting Feedback",
                  variant: "danger",
                  solid: true,
                  timeOut: 3000
              });
              return
          });

        } catch(e){
          this.$bvToast.toast("please check the information you provided is correct and try again.", {
            title: "Unexpected Error When Submitting Feedback/Question",
            variant: "danger",
            solid: true,
            timeOut: 3000
          });
        }

    },
    closeContactFormModal: function(){
        this.$emit('closeContactFormModal', {"message": "canceled"});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
