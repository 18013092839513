import Vue from 'vue'
import store from './store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueMeta from 'vue-meta';
import App from './App';
import router from './router'
import VueGtag from "vue-gtag";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
Vue.use(VueGtag, {
  config: { id: "G-D82SNLZ71T" }
});

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>'
})