import actions from './actions';
import getters from './getters';
import { state, mutations } from './mutations';

export default {
    namespaced: true,
	state,
	actions,
    mutations,
    getters
};