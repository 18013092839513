<template>
    <circle  
    :id="`${pin.landmark}_underReview`" 
    class="medium" :cx=pin.cx :cy=pin.cy r="2%" 
    fill="grey" stroke="lightgrey" stroke-width="0.5%">
      <b-popover :target="`${pin.landmark}_underReview`" triggers="hover" placement="top">
        <template #title>{{pin.landmark}} (Under Review)</template>
        Thank you for your valuable submission! We are currently reviewing your contribution.
    </b-popover>
    </circle>
</template>

<script>

export default {
  name: 'UnderReviewPin',
  props : {
      pin: Object
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
