<template>
  <b-navbar id="navBar" toggleable="lg" type="dark" class="mb-1">
    <b-navbar-brand href="#">Been There, Mug That</b-navbar-brand>

    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="#" v-b-modal.aboutUsModal>About</b-nav-item>
        <b-nav-item href="#" @click="openContactFormModal">Contact</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
    <AboutModal id="aboutUsModal" />
    <ContactFormModal :isShowModal="isShowContactForm"
    v-on:closeContactFormModal="closeContactFormModal" />
  </b-navbar>

</template>

<script>
import AboutModal from './AboutModal';
import ContactFormModal from './ContactFormModal';
export default {
  name: 'NavBar',
  components: {
    AboutModal,
    ContactFormModal
  },
  data: function()
  {
    return {
      isShowContactForm: false,
    }

  },
	computed: {
	},
  methods:{
    openContactFormModal: function(){
      this.isShowContactForm = true;
    },
    closeContactFormModal: function(){
      this.isShowContactForm = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#navBar{
  background-color: #6f4e37;
}
</style>
