<template>
  <div class="beenThere">
    <NavBar />
    <div class="container">
    <b-alert show class="mb-2"> 
      <h4 class="alert-heading">Welcome to Been There, Mug That!</h4>
      <p>
        This site is very new and will be missing a lot of Been There mugs from the starbucks collection.
        Please help us fullfil our mission of detailing all of the mug shot landmarks by <u id="alertSubmitMugs" @click="openMugShotForm"> submitting your mugs</u>! 
      </p>
    </b-alert>
      <div id='searchForm'>
        <b-input-group size="lg" class="mb-1">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input id="searchBox" type="search" placeholder="Search mugs"
          v-model="inputSearch"></b-form-input>
        </b-input-group>
      </div>
      <div class= "mb-1">
        <b-input-group  class="mt-3">
          <template #prepend class="mt-3" >
            <b-input-group-text id="lblAddMug">Can't find your mug?</b-input-group-text>
          </template>
          <b-input-group-append>
          <b-button variant="primary" ref="btnAddMug" @click="openMugShotForm">Submit Your Mug Shot!</b-button>
        </b-input-group-append>
        </b-input-group>
      </div>
      <div id="mugList" style="margin-top: 1%" class="card-deck">
        <div v-for="mugDetail in selectedMugs" :key="mugDetail.mugName">
        <b-card  
          :key="mugDetail.mugName"
          :title="mugDetail.mugName"
          :img-src="mugDetail.image"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem;"
          class="mugshotCards"
          @click="openMugShotDetails(mugDetail.mugName)"
        >
          <b-button variant="primary" @click="openMugShotDetails(mugDetail.mugName)">View</b-button> 
        </b-card>
        </div>
      </div>
    </div>
    <MugShotFormModal id='mugShotFormModal' 
      :isShowModal=isShowMugShotForm
      v-on:submitMugShot="submitMugShot"
      v-on:closeMugShotForm="closeMugShotForm"
    />
    <MugShotDetails id='mugShotDetail' 
      :mugDetail="currentMugDetail"
      :isShowModal="isShowMugShotDetail"
      v-on:closeMugShotDetail="closeMugShotDetail"
    />
  </div>

</template>

<script>
import MugShotFormModal from './MugShotFormModal';
import MugShotDetails from './MugShotDetails';
import NavBar from './NavBar';
import {mugDetails} from './mugDetails.js';

export default {
  name: 'BeenThere',
  components: {
    MugShotFormModal,
    MugShotDetails,
    NavBar,
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [{
        name: "description",
        content: this.metaDescription
      }]
    }
  },
  data: function(){
    return {
      mugDetails: mugDetails,
      currentMugDetail: {},
      inputSearch: "",
      isShowMugShotForm: false,
      isShowMugShotDetail: false,
    }
  },
  mounted: function() {
    const destination = this.$route.query.destination;
    if (destination){
      this.openMugShotDetails(this.$route.query.destination);
    }
  },
  computed: {
    metaTitle: function(){
      var defaultTitle = "Been There Mug That";
      if (!('mugName' in this.currentMugDetail)){
        return defaultTitle;
      }
      return `${this.currentMugDetail.mugName} - ${defaultTitle}`;
    },
    metaDescription: function(){
      var defaultDescription = "Starbucks Been There Mugs from the Been There Collection explained";
      if (!('mugName' in this.currentMugDetail)){
        return defaultDescription;
      }
      var landmarks = this.currentMugDetail.pins.map((p) => p.landmark).join(",");
      var landmarksDescription = `${this.currentMugDetail.mugName} landmarks include: ${landmarks}`;
      console.log(landmarksDescription);
      return `${this.currentMugDetail.mugName} Starbucks Been There Mug from 
                            the Been There Collection explained. ${landmarksDescription}`;
    },
    selectedMugs: function(){
      if (this.inputSearch.length < 1) { return this.mugDetails }
      const matchedInputs = this.mugDetails.filter(mugDetail => {
        return mugDetail.mugName.toLowerCase()
          .startsWith(this.inputSearch.toLowerCase())});
      return matchedInputs;
    }
  },
  methods: {
    track(){
      this.$gtag.pageview({ page_path: '/home' })
    },
    openMugShotDetails: function(mugName){
      const selectedMugDetails = this.mugDetails.filter(mugDetail => {
        return mugDetail.mugName.toLowerCase()
          .startsWith(mugName.toLowerCase())});
      if (selectedMugDetails.length > 0){
        const mugDetail = selectedMugDetails[0];
        this.currentMugDetail = mugDetail;
        this.isShowMugShotDetail = true;
        this.$router.push({path: "/", query: {"destination": mugDetail.mugName}});
      }
    },
    closeMugShotDetail: function(){
      this.isShowMugShotDetail = false;
      this.currentMugDetail = {};
      if (this.$route.query.destination){
        this.$router.push({path: "/", query: {}});
      }
    },
    openMugShotForm: function(){
      this.isShowMugShotForm = true;
    },
    closeMugShotForm: function(){
      this.isShowMugShotForm = false;
    },
    submitMugShot: function(){
      this.isShowMugShotForm = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#mugList {
  margin-top: 5%;
}
#lblAddMug{
  background-color: transparent;
  border: none;
}
#alertSubmitMugs{
  font-weight: bold;
}
#alertSubmitMugs:hover{
  font-weight:800;
  cursor: pointer;
}
.mugshotCards{
  margin-bottom: 1%;
}
.mugshotCards:hover{
  cursor: pointer;
}
</style>
