<template>
    <b-modal v-model=isShowModal hide-footer  title="Send Correction"
    @hide="closeCorrectionModal">
      <b-form @submit.stop.prevent="submitCorrection">
        <b-form-group
          id="input-group-1"
          label="Email address:"
          label-for="input-1"
          description="We'll never share your email with anyone else."
        >
          <b-form-input
            id="inputEmail"
            v-model="correctionForm.email"
            type="email"
            placeholder="Enter email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Name:"
          label-for="input-2"
        >
          <b-form-input
            id="inputName"
            v-model="correctionForm.name"
            type="text"
            placeholder="Enter name (Optional)"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Correction:"
          label-for="input-3"
        >
        <b-form-textarea
          id="textarea"
          v-model="correctionForm.correction"
          placeholder="Enter correction..."
          rows="3"
          max-rows="6"
          required
        ></b-form-textarea>
        </b-form-group> 
      <b-button class="mt-3" variant="primary" block type="submit">Submit</b-button>
      <b-button class="mt-3" variant="outline-danger" block @click="closeCorrectionModal">Cancel</b-button>
      </b-form>
  </b-modal>
</template>

<script>

export default {
  name: 'CorrectionModal',
  props: {
    isShowModal: Boolean,
    destination: String,
    landmarkName: String,
  },
  data: function(){
      return {
        correctionForm: {
            email: '',
            name: '',
            correction: ''
        }
      }
  },
  methods: {
    submitCorrection: function(event){
        event.preventDefault();        
        try{
          const formattedResult = {
              subject: "Landmark Correction",
              email: this.correctionForm.email,
              name: this.correctionForm.name,
              correction: this.correctionForm.correction,
              landmarkName: this.landmarkName,
              destination: this.destination
          }
          fetch("/.netlify/functions/sendemail", {
            method: "POST",
            body: JSON.stringify(formattedResult),
          }).then((res) => {
          if (res.status == 200) {
            this.$bvToast.toast("Thank you for your correction submission. It is now under review", {
                title: "Correction Submitted",
                variant: "success",
                solid: true,
                timeOut: 3000
            });
            this.$emit('submitCorrection', this.correctionForm);
          }
          else{
            this.$bvToast.toast("please check the information you provided is correct and try again.", {
                title: "Unexpected Error When Submitting Correction",
                variant: "danger",
                solid: true,
                timeOut: 3000
            });         
          }
          }).catch(err =>{
            console.log(err);
            this.$bvToast.toast("please check the information you provided is correct and try again.", {
                title: "Unexpected Error When Submitting Correction",
                variant: "danger",
                solid: true,
                timeOut: 3000
            });
          })       
        } catch(e){
          this.$bvToast.toast("please check the information you provided is correct and try again.", {
            title: "Unexpected Error When Submitting Correction",
            variant: "danger",
            solid: true,
            timeOut: 3000
          });
        }

    },
    closeCorrectionModal: function(){
        this.$emit('closeCorrectionModal', {"message": "correction canceled"});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
